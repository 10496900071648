export const environment = {
    production: true,
    apibase: 'https://fp.rental-1.makalu.live/api',
    apiVersion: 'v1',
    wsbase: 'https://fp.rental-1.makalu.live/api',
    authorityUrl: 'https://auth.rental-1.makalu.live/auth',
    realm: 'rental-1',
    clientId: 'file-processing-ui',
    uiVersion: '1.0.20'
};
